import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ColorSwatches from "~/components/ColorSwatches/ColorSwatches";
import TokenTable from "~/components/TokenTable/TokenTable";
export const _frontmatter = {
  "menuLabel": "Box Shadow",
  "sortOrder": 5.3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Box Shadow`}</h1>
    <p className="p-large">Elevate components with drop shadows to create hierarchy and emphasis.</p>
    <p>{`Here are some early guidelines on what elements should receive which drop shadows:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`2 extra small (2xs)`}</strong>{` tokens are for text, icons and borders.`}</li>
      <li parentName="ul"><strong parentName="li">{`Extra small (xs)`}</strong>{` tokens are for cards and buttons`}</li>
      <li parentName="ul"><strong parentName="li">{`Small (sm)`}</strong>{` tokens are for hover states for cards and buttons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Medium (md)`}</strong>{` tokens are navigational drawers and bars. `}</li>
      <li parentName="ul"><strong parentName="li">{`Large (lg)`}</strong>{` tokens are for floating action buttons. `}</li>
      <li parentName="ul"><strong parentName="li">{`Extra large (xl)`}</strong>{` tokens are for modals. `}</li>
      <li parentName="ul"><strong parentName="li">{`2 extra large (2xl)`}</strong>{` tokens are for dialogs.`}</li>
    </ul>
    <p>{`Components in the design system will ultimately have drop shadows automatically applied to them.`}</p>
    <TokenTable tokenGroup="Shadow" type="Semantic" mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      